/* ��� ������������� ������������ ������ ������ � ������������� */
@define-mixin i-inline {
	background-repeat: no-repeat;
	display: inline-block;
	margin-top: -.2em;
	vertical-align: middle;
}
.i-inline {
	@mixin i-inline;
}

/* ����� ��������� �������� �� ��������� ������ */
@define-mixin groupIB {
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
}
.groupIB {
	@mixin groupIB;
}

/* ��� ��������� ����������� ��������� */
@define-mixin ieOverlay {
	.ielte8 & {
		border: 1px solid #e2e2e2;
	}
}

