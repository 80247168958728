/* �������� ����� */
$BLOCK_BG: #f7f4f1;

$MAIN: #379014;
$MAIN__A: #47bc19;

$ACTION_MAIN: #006cb5;
$ACTION_MAIN__A: #048be5;

$BRAND: #c22119;
$BRAND__A: #ff0030;

$ATTENTION: #ffeab1;

$BG: #f2eeea;
$BG__A: #c7c2be;
$BG_H: #e9e4e1;

$ERROR: #c22119;

$INPUT_CONTROL: #e2ddd9;
$INPUT_CONTROL_H: #eae5e2;
$INPUT_CONTROL_A: #f0ede9;


/* ����� ������ */
$C_ERROR: #e10000;
$C_PZK: #a7126a;
$C_DISABLED: #999;
$C_ADD: #666;
$C_INFO: #666;

$C_ACTION: #404040;


/* ����� ������ */
$B_LIGHT: #f2eeea;
$B_DISABLED: #d7d5d2;
$B_ACTION: #e2ddd9;
$B_ACTION__A: #ccc6c2;


/* ����� ����������� ������ */
$BTN_BG: #e2ddd9;
$BTN_BG_H: #e9e4e1;
$BTN_BG_A: #f0ece8;

$PAGE_WRAPPER_PADDING: 25px;

$SIDE_WIDTH: 220px;
$SIDE_MARGIN: 20px;

$BD_RAD: 2px;

$FORM_WIDTH: 500px;

$BX_SH: 0 2px 15px rgba(0,0,0,.4);

/* ������� ��� �������� */

$ANIM_FUN: cubic-bezier(0.42, 0, 0.08, 1);

